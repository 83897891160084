import {withRoute} from '@hconnect/uikit'

import {Overview} from './Overview'

export const ROUTE = '/overview'

export default withRoute({
  path: ROUTE,
  label: 'Overview',
  labelKey: 'overview.navigationLabel',
  order: 0,
  routeProps: {exact: true}
})(Overview)
