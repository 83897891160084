/* eslint-disable complexity */
import {User} from '@hconnect/apiclient/src'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Content, Page} from '@hconnect/uikit'
import {Box, CircularProgress} from '@material-ui/core'
import queryString from 'query-string'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector, useDispatch} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {v4 as uuidV4} from 'uuid'

import {sheetRequest} from '../../../AsyncJobQueue/AsyncJobQueue.action'
import {
  ApiDownloadRequestFormats,
  ApiDownloadRequestTypes
} from '../../../AsyncJobQueue/AsyncJobQueue.types'
import {useContainerStyles} from '../../../common/styles/containerStyles'
import ExportDropdown from '../../../Molecules/ExportDropdown'
import {withRouteRequiringPermission} from '../../../Molecules/PermissionBoundary'
import RequestErrorSnackbar from '../../../Molecules/RequestErrorSnackbar'
import {useResponsiveBreakpoints} from '../../../Molecules/Responsive.utils'
import {TitleNavigation} from '../../../Molecules/TitleNavigation'
import {selectSelectedCustomer} from '../../../Organisms/Customers'
import {Customer} from '../../../Organisms/Customers/Customers.types'
import {errorsClear} from '../../../Organisms/Errors'
import {useFeaturesState} from '../../../Organisms/Features'
import {PermissionTypes} from '../../../Permissions'
import {AppState} from '../../../Root.store'
import {useDeliveriesByOrderId} from '../hooks'
import {ROUTE as OrderRoute} from '../index'
import {
  downloadOrderConfirmation,
  getOrderConfirmationLink,
  useAllFeaturesOrderItems
} from '../Order.utils'

import {useOrder, usePlants} from './hooks'
import {OrderDetailsHeader} from './OrderDetailsHeader'

const OrderDetail: React.FC = () => {
  const {smallScreen} = useResponsiveBreakpoints()
  const {classes} = useContainerStyles()
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const goBack = () => history.goBack()
  const sendEmail = true
  const {host} = (window as Window).location
  const url = `${RegExp(/localhost/).exec(host) ? 'http' : 'https'}://${host}/downloadRequests`
  const {customerId} = queryString.parse(window.location.search)
  const {id: groupId} = useParams<{id: string}>()
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)
  const {name: fullName, eMail, country: userCountry} = user || {}
  const [jobId, setJobId] = React.useState<string | undefined>(undefined)

  const handleExportClick = () => {
    const jobIdTemp = uuidV4()
    setJobId(jobIdTemp)
    trackEvent('hubExportClick', {
      product: 'hub',
      jobId: jobIdTemp,
      entryPoint: 'order',
      userId: user?.user_id || '',
      userCountry: user?.country,
      customerId,
      customerName: selectedCustomer?.customerName,
      lookUpQuery: order?.orderNumber,
      lookUpCategory: 'orderNumber'
    })
  }
  const handleExportClose = () => setJobId(undefined)

  const selectedCustomer = useSelector<AppState, Customer | undefined>((state: AppState) =>
    selectSelectedCustomer(state, customerId)
  )

  const {getFeature} = useFeaturesState()
  const {
    isGroupedDeliveries,
    isPumpServiceIncluded,
    isServiceOrderIncluded,
    isFilteringOutCancelledDeliveries,
    isFilteringCancelledOrders,
    includeDeliveryInstructions,
    expandToOrderDocuments
  } = useAllFeaturesOrderItems()
  const includeOrderChanges = getFeature('OrderChangeTrail')

  const {
    isFetching,
    data: order,
    error
  } = useOrder({
    groupId,
    selectedCustomer,
    isGroupedDeliveries,
    isPumpServiceIncluded,
    includeOrderChanges,
    isServiceOrderIncluded,
    excludeCancelledOrders: isFilteringCancelledOrders,
    includeDeliveryInstructions,
    expandToOrderDocuments
  })

  const {isFetching: isDeliveriesFetching, data: deliveries} = useDeliveriesByOrderId(
    selectedCustomer?.customerId,
    groupId,
    isGroupedDeliveries,
    isFilteringOutCancelledDeliveries,
    order?.orderIds,
    isPumpServiceIncluded,
    'deliveryNumber',
    'desc'
  )
  const {isFetching: isPlantsFetching, data: plant} = usePlants(
    order?.businessLine,
    deliveries && deliveries.length ? deliveries[0].plantName : undefined,
    deliveries && deliveries.length ? deliveries[0].plantId : undefined
  )
  const isLoading = isFetching || isDeliveriesFetching || isPlantsFetching

  const cementPlant = plant
    ? plant
    : deliveries && deliveries.length && deliveries[0].plantDetails
      ? deliveries[0].plantDetails
      : undefined

  const analytics = {
    userId: user?.user_id,
    userMail: user?.eMail,
    userCountry: user?.country,
    userRoles: user?.hasRoles,
    userIsInternal: user?.isInternal,
    customerId: selectedCustomer?.customerId,
    customerName: selectedCustomer?.customerName,
    downloadedItemBusinessLine: order?.businessLine,
    salesOrg: order?.orgUnitId,
    orderId: order?.orderId,
    details: true
  }

  const exportOrder = (format: ApiDownloadRequestFormats) => {
    if (!order) return
    dispatch(
      sheetRequest({
        jobId,
        criteria: {
          customerId,
          orderNumber: order.orderNumber
        },
        type: ApiDownloadRequestTypes.orderDeliveryExport,
        format: format,
        name: `order-deliveries-${order.orderNumber}.${format}`,
        shortName: `order-${order.orderNumber}`,
        fileType: format === ApiDownloadRequestFormats.xlsx ? 'Excel' : 'CSV',
        dateRangeInDays: '1',
        email: eMail || '',
        sendEmail,
        fullName: fullName || '',
        url,
        country: userCountry || '',
        clientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        analytics: {
          jobId,
          numberOfDaysSelected: '1',
          initiationSource: 'hub'
        }
      })
    )
    trackEvent('hubExportStart', {
      product: 'hub',
      jobId,
      fileFormat: format === ApiDownloadRequestFormats.xlsx ? 'excel' : 'csv',
      entryPoint: 'order',
      userId: user?.user_id || '',
      userCountry: user?.country,
      customerId,
      customerName: selectedCustomer?.customerName
    })
  }

  const canDownloadOrderConfirmation = order && getOrderConfirmationLink(order)

  let page, exportButton

  if (!selectedCustomer || isLoading) {
    page = (
      <Page variant="sheet" className={classes.pageComponentContainer}>
        <CircularProgress
          style={{display: 'block', margin: '0 auto'}}
          data-test-id="invoice-detail-loader"
        />
      </Page>
    )
  } else if (!order && !!error) {
    page = (
      <Page variant="sheet" className={classes.pageComponentContainer}>
        <RequestErrorSnackbar error={error} />
      </Page>
    )
  } else if (order) {
    exportButton = (
      <ExportDropdown
        responsiveStyle={smallScreen ? 'mobile' : 'desktop'}
        data-test-id="orders-export-dropdown"
        title={t('order.export.buttontitle')}
        createJobId={handleExportClick}
        onClose={handleExportClose}
        options={[
          {
            name: 'orders-excel-export',
            label: t('order.export.ordersToExcel'),
            onClick: () => exportOrder(ApiDownloadRequestFormats.xlsx)
          },
          {
            name: 'orders-csv-export',
            label: t('order.export.ordersToCsv'),
            onClick: () => exportOrder(ApiDownloadRequestFormats.csv)
          },
          ...(canDownloadOrderConfirmation
            ? [
                {
                  name: 'orders-download-confirmation',
                  label: t('order.orderDetails.downloadPdf'),
                  onClick: () =>
                    downloadOrderConfirmation(order, user, 'Order details export', t, dispatch)
                }
              ]
            : [])
        ]}
      />
    )
    page = (
      <Page
        boxed={false}
        data-test-id={`order-full-details-page-${order.orderId}`}
        className={classes.pageComponentContainer}
        variant="sheet"
        px={2}
        py={0}
      >
        <Box data-test-id={`order-full-details-${order.orderId}`}>
          <OrderDetailsHeader
            order={order}
            isLoading={isLoading}
            deliveries={deliveries}
            plant={cementPlant}
            analytics={analytics}
            customer={selectedCustomer}
            isDeliveriesFetching={isDeliveriesFetching}
          />
        </Box>
        <RequestErrorSnackbar error={error} onClose={errorsClear} />
      </Page>
    )
  } else {
    page = (
      <Page variant="sheet" className={classes.pageComponentContainer}>
        {t('invoiceDetail.404')}
      </Page>
    )
  }

  return (
    <Content style={{padding: '4px 8px 8px 8px'}}>
      <div className={classes.pageContainer}>
        <TitleNavigation
          title={t('order.orderDetails.navigationBack')}
          onClick={goBack}
          ariaLabel="Back to all orders button"
          dataTestId="order-details-back-button"
          exportButton={exportButton}
        />
        {page}
      </div>
    </Content>
  )
}

const ROUTE = `${OrderRoute}/:id`

// eslint-disable-next-line import/no-default-export
export default withRouteRequiringPermission({
  path: ROUTE,
  hideInMainNav: true,
  routeProps: {exact: true},
  label: 'Order Details',
  permissions: [PermissionTypes.VIEW_ORDERS_DELIVERIES, PermissionTypes.VIEW_ALL_DATA]
})(OrderDetail)
