import {withRoute} from '@hconnect/uikit'

import {withRouteRequiringPermission} from '../../Molecules/PermissionBoundary'

import {PartnersPage} from './Partners'

export const ROUTE = '/partners'
export const C60Route = '/c60'

export const PartnersRoute = withRouteRequiringPermission({
  path: ROUTE,
  label: 'Our Partners',
  labelKey: 'lead.partners.navigationLabel',
  routeProps: {exact: true},
  order: 1005,
  featureToggleName: ['Partners'],
  checkByApi: true
})(PartnersPage)

withRoute({
  path: `${ROUTE}${C60Route}`,
  hideInMainNav: true,
  label: 'C60',
  labelKey: 'lead.c60.navigationLabel',
  featureToggleName: 'Partners',
  routeProps: {exact: true}
})(() => null)
