import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import qs from 'qs'

import {api} from '../../../App.global'
import {Delivery} from '../../Finance/Invoices/Delivery.types'

export const useDeliveriesByOrderId = (
  customerId?: string | null,
  orderId?: string,
  isGroupedDeliveries = false,
  isFilteringOutCancelledDeliveries = false,
  ordersIds?: string[],
  isPumpServiceIncluded = false,
  sortedByKey = 'deliveryNumber',
  sortedByDirection = 'desc'
) => {
  return useQuery(
    [
      'deliveries',
      {
        customerId,
        orderId,
        sortedByKey,
        sortedByDirection,
        ordersIds,
        isPumpServiceIncluded,
        isFilteringOutCancelledDeliveries
      }
    ],
    async () => {
      const endPoint = isGroupedDeliveries ? '/deliveries/forOrders' : '/deliveries'
      const orderIdsParam = ordersIds || [orderId]
      const params = {
        orderId,
        ...(isGroupedDeliveries ? {ordersIds: orderIdsParam} : {}),
        customerId,
        sortedBy: `${sortedByKey} ${sortedByDirection}`,
        ...(isFilteringOutCancelledDeliveries ? {excludeCancelledDeliveries: true} : {}),
        ...(isPumpServiceIncluded ? {includePumpService: true} : {})
      }
      const res: AxiosResponse<Delivery[]> = await api.get(endPoint, {
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, {arrayFormat: 'repeat'})
        }
      })
      return res.data
    },
    {
      enabled: !!customerId && !!(orderId || ordersIds?.length),
      staleTime: 1000 * 60
    }
  )
}
