/* eslint-disable complexity */
import {
  Typography,
  dateFormatter,
  SectionHeader,
  timeFormatter,
  getUtcOffset,
  PermissionTypes
} from '@hconnect/uikit'
import {Box, CircularProgress, Grid, Theme} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {Skeleton} from '@mui/material'
import moment from 'moment'
import React from 'react'
import {useTranslation} from 'react-i18next'

// eslint-disable-next-line import/no-cycle
import {BusinessLineType} from '../../../common/types'
import {Address} from '../../../Molecules/Address'
import {DeliveryProgressBar} from '../../../Molecules/DeliveryProgressBar'
import MaterialDescription from '../../../Molecules/MaterialDescription'
import {useResponsiveBreakpoints} from '../../../Molecules/Responsive.utils'
import StatusIcon from '../../../Molecules/StatusIcons'
import StatusTooltip from '../../../Molecules/StatusTooltip'
import {Customer} from '../../../Organisms/Customers/Customers.types'
import {Features, useFeaturesState} from '../../../Organisms/Features'
import {statusFromOrderStatus} from '../../../Organisms/Orders'
import {Plant} from '../../../Organisms/Plants/Plants.types'
import {usePermissions} from '../../../Permissions'
import {Delivery, ShippingType} from '../../Finance/Invoices/Delivery.types'
import {
  DeliveriesList,
  FieldDetails,
  FieldValue,
  LoadingNumberValue,
  QRCodeValue,
  RequestedDate,
  TimezoneWarning,
  OrderCancellationButton,
  OrderNumber,
  PlacedDate
} from '../components'
import {QuantityUomToSymbolComponent} from '../Order.components'
import {Order, OrderLineItem, OrdersAnalytics} from '../Order.types'
import {
  getEventLocationOffset,
  getIsAGG,
  getIsCEM,
  getUnloadingMethod,
  showProgressBar,
  extractMultipleOrderNumbers,
  getExtras
} from '../Order.utils'

import {OrderHistoryDetails} from './OrderHistoryDetails'

const useStyles = makeStyles((theme: Theme) => ({
  overallContainer: {
    marginTop: 0
  },
  invoiceDetailsContainer: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  },
  costOverviewContainer: {
    flexGrow: 1,
    borderRadius: 4,
    border: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: '100%'
  },
  totalCostContainer: {
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    backgroundColor: theme.palette.grey[100]
  },
  divider: {
    margin: `${theme.spacing(3)}px 0`
  }
}))

interface OrderDetailsHeaderType {
  order?: Order
  deliveries?: Delivery[]
  plant?: Plant
  isLoading: boolean
  customer: Customer
  analytics: OrdersAnalytics
  isDeliveriesFetching: boolean
}
export const OrderDetailsHeader: React.FC<OrderDetailsHeaderType> = ({
  order,
  deliveries,
  plant,
  isLoading,
  customer,
  analytics,
  isDeliveriesFetching
}) => {
  const {overallContainer, invoiceDetailsContainer, costOverviewContainer, totalCostContainer} =
    useStyles()
  const {t} = useTranslation()
  const {smallScreen} = useResponsiveBreakpoints()

  const {getFeature, getResolutionByNameAndConstraint, normalized} = useFeaturesState()
  const {hasPermission} = usePermissions()
  const useTruckLicensePlate = getFeature('TruckLicensePlate')
  const progressBarEnabled = order
    ? showProgressBar(
        order,
        !getFeature('OrderProgressBar'),
        getFeature('ShowStatusLoadedToInTransit')
      )
    : false
  const isDeliveryLoadFinished = getFeature('DeliveryLoadFinished')
  const epodEnabledForOrderExpand = getFeature('ePOD')
  const isCustomerDeliveryNumber = getFeature('CustomerDeliveryNumber')
  const isSloCountry = getFeature('SLO2Countries')
  const aggColumnsForRmc = getFeature('AggColumnsForRmc')
  const isFilteringOutCancelledDeliveries = getFeature('ExcludeAllCancelledDeliveries')
  const hasInvoicePermission = hasPermission(PermissionTypes.VIEW_INVOICES)
  const hasChangeOrderPermission = hasPermission(PermissionTypes.CHANGE_ORDERS)
  const isCEM = order?.businessLine === BusinessLineType.CEM
  const isRMC = order?.businessLine === BusinessLineType.RMC
  const isAGG = order?.businessLine === BusinessLineType.AGG
  const isInvoiceDetailsDisabled = (invoiceId: string) =>
    getResolutionByNameAndConstraint('InvoiceDetails', normalized, 'connectorId').disabled.includes(
      invoiceId.split('.')[0]
    ) || !getFeature('Finance')

  const {businessLine, shippingType, orderPlacerName} = order || {}
  const {quantityUom} = order?.lineItems[0] || {}
  const {arrivalSpacing, quantityPerHour} = order?.schedules[0] || {}
  const [viewAll, setViewAll] = React.useState<boolean>(false)

  const hideCancelButtonStatuses = ['delivered', 'closed', 'paymentPending', 'rejected']

  const flowRateAvailable =
    order?.schedules && Array.isArray(order?.schedules) && !!order.schedules[0].quantityPerHour
  const flowRateSpacingFormatted =
    order?.schedules && Array.isArray(order.schedules) ? (
      flowRateAvailable ? (
        <>
          {quantityPerHour} <QuantityUomToSymbolComponent uom={quantityUom} />{' '}
          {t('order.orderDetails.quantityPerHour')}
        </>
      ) : (
        <>
          {moment.duration(arrivalSpacing).asMinutes()} {t('order.orderDetails.minutes')}
        </>
      )
    ) : (
      '-'
    )

  const status = order ? statusFromOrderStatus(order.orderStatus, order.shippingType) : null

  const truckName = deliveries?.length
    ? useTruckLicensePlate
      ? deliveries[0].truckLicensePlate
      : deliveries[0].truckName
    : undefined

  const extras = getExtras(order)

  if (isLoading) {
    return (
      <CircularProgress
        style={{display: 'block', margin: '0 auto'}}
        data-test-id="payment-options-loader"
      />
    )
  }

  if (!order) {
    return null
  }

  const deliveryList = (
    <>
      <Box my={smallScreen ? 2 : 4} />
      {isDeliveriesFetching ? <Skeleton height="300px" width="100%" variant="rectangular" /> : null}
      {deliveries?.length ? (
        <DeliveriesList
          order={order}
          analytics={analytics}
          deliveries={deliveries}
          loading={isDeliveriesFetching}
          toggles={{
            isCustomerDeliveryNumber,
            useTruckLicensePlate,
            isAGG,
            isCEM,
            isRMC,
            epodEnabledForOrderExpand,
            isSloCountry,
            aggColumnsForRmc,
            isDeliveryLoadFinished,
            isFilteringOutCancelledDeliveries,
            hasInvoicePermission,
            isInvoiceDetailsDisabled
          }}
          customerId={customer?.customerId}
          viewAll={viewAll}
          setViewAll={setViewAll}
        />
      ) : null}
    </>
  )

  return (
    <Grid container spacing={4} className={overallContainer}>
      <Grid item xs={12} md={8}>
        <Box
          pt={1.375}
          pb={2}
          className={invoiceDetailsContainer}
          mb={3}
          data-test-id="order-detail-header"
        >
          <Box display="flex" justifyContent="space-between">
            <SectionHeader
              title={t(`order.orderDetails.${order.businessLine || 'title'}`)}
              first
              second={
                <Box display="flex" alignItems="center" justifyContent="space-around">
                  <StatusTooltip
                    title={t(`order.orderStatusLabel.tooltip.${status}`)}
                    placement="top-start"
                    data-test-id={`order-status-tooltip-${order.orderId}`}
                  >
                    <Box display="flex" flexDirection="column" alignItems="flex-end">
                      <Typography customVariant="formLabel">
                        {t('order.orderDetails.statusLabel')}
                      </Typography>

                      <Box display="flex" alignItems="center">
                        <StatusIcon status={order.orderStatus} shippingType={shippingType} small />

                        <>
                          <Box mx={0.5} />
                          <Typography variant="body2">
                            {t(`order.orderStatusLabel.${status}`)}
                          </Typography>
                        </>
                      </Box>
                    </Box>
                  </StatusTooltip>
                </Box>
              }
            />
          </Box>
          <Grid container spacing={1}>
            {!order.isHaveMoreThanOne && (
              <FieldDetails
                dataTestId="order-number"
                label={t('order.orderDetails.orderNumber')}
                value={<OrderNumber order={order} entryPoint="Order details page" t={t} />}
                gutter
              />
            )}
            <FieldDetails
              dataTestId="customer-reference"
              label={t('order.orderDetails.customerReference')}
              value={order?.customerReference || '-'}
              gutter
            />
            <FieldDetails
              dataTestId="contract-number"
              label={t('order.orderDetails.contractNumber')}
              value={order?.contractNumber}
              businessLine={businessLine}
              showForBusinesslines={[BusinessLineType.CEM, BusinessLineType.AGG]}
              gutter
            />
            <Features name="RMCTestReports">
              <FieldDetails
                dataTestId="test-booked"
                label={t('order.orderDetails.testBooked')}
                value={t(`order.isTestBooked.${order?.isTestBooked ? 'booked' : 'none'}`)}
                businessLine={businessLine}
                showForBusinesslines={[BusinessLineType.RMC]}
                gutter
              />
            </Features>
            {arrivalSpacing || quantityPerHour ? (
              <FieldDetails
                dataTestId="unload-spacing"
                label={
                  quantityPerHour
                    ? t('order.orderDetails.flowRate')
                    : t('order.orderDetails.spacing')
                }
                value={flowRateSpacingFormatted}
                businessLine={businessLine}
                showForBusinesslines={[BusinessLineType.RMC]}
                gutter
              />
            ) : null}
            <FieldDetails
              dataTestId="unloading-method"
              label={t('order.orderDetails.unloadingMethod.label')}
              value={getUnloadingMethod(t, order.unloadingMethod)}
              businessLine={businessLine}
              showForBusinesslines={[BusinessLineType.RMC]}
              gutter
            />
            <FieldDetails
              dataTestId="shipping-type"
              label={t('order.orderDetails.shippingTypeLabel')}
              value={order.shippingType ? t(`order.orderDetails.${order.shippingType}`) : undefined}
              gutter
            />
            <FieldDetails
              dataTestId="site-contact-name"
              label={t('order.orderDetails.siteContactName')}
              value={order?.contactInformation?.siteContactName}
              gutter
            />
            <FieldDetails
              dataTestId="site-contact-number"
              label={t('order.orderDetails.siteContactNumber')}
              value={order?.contactInformation?.siteContactNumber}
              gutter
            />
            <FieldDetails
              dataTestId="order-placer-name"
              label={t('order.orderDetails.orderPlacerName')}
              value={orderPlacerName}
              businessLine={businessLine}
              showForBusinesslines={[BusinessLineType.CEM, BusinessLineType.AGG]}
              gutter
            />
            <FieldDetails
              dataTestId="vehicle-type"
              label={t('order.orderDetails.vehicleType')}
              value={
                deliveries && deliveries.length && deliveries[0].modeOfTransport
                  ? t(`invoice.expand.modeoftransport.${deliveries[0].modeOfTransport}`)
                  : undefined
              }
              businessLine={businessLine}
              showForBusinesslines={[BusinessLineType.CEM]}
              shippingType={shippingType}
              showForShippingType={ShippingType.Deliver}
              gutter
            />
            <FieldDetails
              dataTestId="vehicle-licence"
              label={t('order.orderDetails.vehicleLicencePlate')}
              value={truckName}
              gutter
              businessLine={businessLine}
              showForBusinesslines={[BusinessLineType.CEM]}
            />
            <FieldDetails
              dataTestId="trailer-licence-plate"
              label={t('order.orderDetails.trailer')}
              value={
                deliveries && deliveries.length && deliveries[0].transportVehicleDetails?.trailer
                  ? deliveries[0].transportVehicleDetails.trailer
                  : undefined
              }
              businessLine={businessLine}
              showForBusinesslines={[BusinessLineType.CEM]}
              shippingType={shippingType}
              showForShippingType={ShippingType.Deliver}
              gutter
            />
            <FieldDetails
              dataTestId="driver-name"
              label={t('order.orderDetails.driverName')}
              value={
                deliveries && deliveries.length && deliveries[0].transportVehicleDetails?.driverName
                  ? deliveries[0].transportVehicleDetails.driverName
                  : undefined
              }
              gutter
              businessLine={businessLine}
              showForBusinesslines={[BusinessLineType.CEM]}
              shippingType={shippingType}
              showForShippingType={ShippingType.Deliver}
            />
            {extras && (
              <FieldDetails
                dataTestId="order-extras"
                label={t('order.orderDetails.extras')}
                value={extras}
                businessLine={businessLine}
                showForBusinesslines={[BusinessLineType.RMC]}
                gutter
              />
            )}
            <LoadingNumberValue order={order} deliveries={deliveries} />
          </Grid>
        </Box>
        <Box data-test-id="order-details-supplement">
          <QRCodeValue order={order} deliveries={deliveries} />
          {progressBarEnabled ? (
            <Box data-test-id="progress-bar">
              <DeliveryProgressBar order={order} noContainer />
            </Box>
          ) : null}
          {!smallScreen ? deliveryList : null}
        </Box>
      </Grid>
      <Grid item xs={12} md={4} aria-label="Cost overview">
        <Box className={costOverviewContainer}>
          <Box pl={2} pr={2} pb={2} pt={1.375}>
            <SectionHeader first title={t('order.orderDetails.summary')} />
            <OrderSummary order={order} plant={plant} />
            <OrderHistoryDetails order={order} />
            <Features name="OrderIntakeOrderCancellation">
              {!hideCancelButtonStatuses.includes(order.orderStatus) &&
                !smallScreen &&
                hasChangeOrderPermission && <OrderCancellationButton order={order} />}
            </Features>
          </Box>
          <Box className={totalCostContainer}></Box>
        </Box>
      </Grid>
      {smallScreen && (
        <Grid item xs={12}>
          {deliveryList}
        </Grid>
      )}
      <Features name="OrderIntakeOrderCancellation">
        {!hideCancelButtonStatuses.includes(order.orderStatus) &&
          smallScreen &&
          hasChangeOrderPermission && (
            <Grid item xs={12}>
              <OrderCancellationButton order={order} />
            </Grid>
          )}
      </Features>
    </Grid>
  )
}

type OrderSummaryProps = {
  order: Order
  plant: Plant | undefined
  isPlantsFetching?: boolean
}

export const OrderSummary: React.FC<OrderSummaryProps> = ({order, plant, isPlantsFetching}) => {
  const {getFeature} = useFeaturesState()

  const showState = getFeature('ShowState')
  const showRecipeNumber = getFeature('ShowRecipeNumber')
  const showDeliveryInstructions = order.purchaseOrderType === 'YHUB'
  const deliveryInstructions = order.note?.itemTexts
    .slice(1, 4)
    .filter((value) => value !== null && value !== undefined)
    .join('')

  const userOffset = getUtcOffset(order.shippingDate)

  const {materialDescription, quantity, quantityUom, customerMaterialDescription}: OrderLineItem =
    order?.lineItems[0] || {}
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const isCem = order ? getIsCEM(order) : false
  const isAgg = order ? getIsAGG(order) : false
  const eventLocationOffset = getEventLocationOffset(order)

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <FieldValue
            label={t('order.orderDetails.requestedDate')}
            dataTestId="requested-date"
            gutter
          >
            {isCem || isAgg ? (
              <RequestedDate
                t={t}
                order={order}
                language={language}
                userOffset={userOffset}
                eventLocationOffset={eventLocationOffset}
              />
            ) : order?.schedules && order.schedules.length ? (
              <>
                {`${dateFormatter(
                  order?.schedules[0].scheduleStartDate,
                  language,
                  eventLocationOffset
                )} ${timeFormatter(
                  order?.schedules[0].scheduleStartDate,
                  language,
                  eventLocationOffset
                )}`}
                <TimezoneWarning
                  t={t}
                  order={order}
                  userOffset={userOffset}
                  eventLocationOffset={eventLocationOffset}
                />
              </>
            ) : null}
          </FieldValue>
        </Grid>
        <Grid item xs={6}>
          <Features name="RMCTestReports">
            <FieldDetails
              dataTestId="test-booked"
              label={t('order.orderDetails.testBooked')}
              value={t(`order.isTestBooked.${order?.isTestBooked ? 'booked' : 'none'}`)}
              businessLine={order.businessLine}
              showForBusinesslines={[BusinessLineType.RMC]}
              gutter
            />
          </Features>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <FieldValue
            label={t('order.orderDetails.placedDate')}
            dataTestId="placed-date"
            gutter
            breakable
          >
            <PlacedDate order={order} />
          </FieldValue>
        </Grid>
      </Grid>
      {order?.shippingAddress ? (
        <FieldValue
          label={t('order.orderDetails.destination')}
          dataTestId="order-destination"
          gutter
        >
          {order.shippingAddress.siteName}
          {order.shippingAddress.street && (
            <Address
              street={order.shippingAddress.street}
              city={order.shippingAddress.city || ''}
              state={order.shippingAddress.state || ''}
              postalCode={order.shippingAddress.postalCode || ''}
              showState={showState}
            />
          )}
        </FieldValue>
      ) : null}
      <Grid container>
        <Grid item xs={6}>
          <FieldValue
            label={t('order.orderDetails.productCode')}
            dataTestId="product-code"
            gutter
            breakable
          >
            <MaterialDescription
              materialDescription={materialDescription}
              customerMaterialDescription={customerMaterialDescription}
            />
          </FieldValue>
        </Grid>
        <Grid item xs={6}>
          <FieldValue label={t('order.orderDetails.orderedQty')} dataTestId="ordered-qty" gutter>
            {quantity} <QuantityUomToSymbolComponent uom={quantityUom} />
          </FieldValue>
        </Grid>
      </Grid>
      {showRecipeNumber && order.recipeNumber && (
        <FieldValue label={t('order.orderDetails.recipeNumber')} dataTestId="recipe-number" gutter>
          {order.recipeNumber}
        </FieldValue>
      )}
      {showDeliveryInstructions && (
        <FieldDetails
          label={t('order.orderDetails.deliveryInstructions')}
          dataTestId="delivery-instructions"
          value={deliveryInstructions}
          fullWidth
          gutter
        />
      )}
      {isCem ? (
        <FieldValue dataTestId="plant-details" label={t('order.orderDetails.plantName')} gutter>
          {isPlantsFetching ? <Skeleton width="60%" /> : plant?.plantName || '-'}
          {plant?.street ? (
            <Address
              street={plant.street}
              city={plant.city || ''}
              state={plant.state || ''}
              postalCode={plant.postalCode || ''}
              showState={showState}
            />
          ) : null}
        </FieldValue>
      ) : null}

      {order.isHaveMoreThanOne && order.orderIds && (
        <FieldValue dataTestId="order-number" label={t('order.orderDetails.orderNumber')} gutter>
          {extractMultipleOrderNumbers(order.orderIds).join(', ')}
        </FieldValue>
      )}
    </>
  )
}
