import {Box} from '@material-ui/core'
import {ParsedQuery} from 'query-string'
import React from 'react'

import CustomersFilter from '../../../Organisms/Customers/CustomersFilter'
import {CustomerSimpleLookup} from '../../../Organisms/Customers/CustomerSimpleLookup'
import {Features, useFeaturesState} from '../../../Organisms/Features'
import {GenericFilterBar} from '../../../Organisms/Filters/GenericFilterBar'
import ProjectsSitesFilter from '../../../Organisms/Projects/ProjectsSitesFilter'
import {Site} from '../../../Organisms/SitesTypeahead'
import {SitesFilter} from '../../../Organisms/SitesTypeahead/SitesTypeahead'
import {usePermissions} from '../../../Permissions'

import DateFilter from './DateFilter'
import LookupFilter from './LookupFilter'
import OrderStatusFilter from './OrderStatusFilter'
import SitesFilterOld from './SitesFilter'
import TestStatusFilter from './TestStatusFilter'
import SupplierNameFilterOrders from '../../../Molecules/SupplierFilter/SupplierFilterOrders'

export interface FilterBarErrorOrder {
  key: string
  label: string
}

interface Props {
  errors?: FilterBarErrorOrder[]
  country?: string
  queryParams?: ParsedQuery<string>
  xs?: boolean
  customerId: string
  site?: Site
  setSite: React.Dispatch<React.SetStateAction<Site | undefined>>
  lookupAnalyticsId?: string
  userId?: string
}

const Filters: React.FC<Props> = ({
  customerId,
  site,
  setSite,
  xs = false,
  lookupAnalyticsId,
  userId,
  ...props
}) => {
  const {displayCustomerSimpleLookup} = usePermissions()
  const {getFeature} = useFeaturesState()
  const customerSearchByNameEnabled = getFeature('CustomersByName')
  const {errors, queryParams} = props
  const isLookupError = errors?.some((err) => err.key === 'noLookup')

  if (xs) {
    return (
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" mb={1} width="100%">
          {displayCustomerSimpleLookup ? (
            <CustomerSimpleLookup
              data-test-id="orders-filters-lookup"
              customerSearchByName={customerSearchByNameEnabled}
            />
          ) : (
            <CustomersFilter onLight customersOnly />
          )}
        </Box>
        <Box width="100%" mb={1}>
          <DateFilter />
        </Box>
        <Features name="DestinationSearchByName" resolution="disabled">
          <Features name="ProjectSitesFilter">
            <Box width="100%" mb={1}>
              <ProjectsSitesFilter onLight />
            </Box>
          </Features>
        </Features>
        <Features name="DestinationSearchByName" resolution="disabled">
          <Features name="ProjectSitesFilter" resolution="disabled">
            <Box width="100%" mb={1}>
              <SitesFilterOld onLight />
            </Box>
          </Features>
        </Features>
        <Features name="DestinationSearchByName">
          <Box width="100%" mb={1}>
            <SitesFilter customerId={customerId} limit={100} setSite={setSite} site={site} />
          </Box>
        </Features>
        <Features name="OrderStatusFilter">
          <Box width="100%" mb={1}>
            <OrderStatusFilter />
          </Box>
        </Features>
        <Features name="RMCTestReports">
          <Box mb={1} width="100%">
            <TestStatusFilter />
          </Box>
        </Features>
        <Features name="ShowSupplierFilter">
          <Box mb={1} width="100%">
            <SupplierNameFilterOrders />
          </Box>
        </Features>
      </Box>
    )
  }

  return (
    <GenericFilterBar data-test-id="orders-filters" toolbarProps={{notEndLast: true}} {...props}>
      <Box width="100%" mt={-1}>
        <Box display="flex" height={57} mt={1} style={{float: 'left'}}>
          {displayCustomerSimpleLookup ? (
            <CustomerSimpleLookup
              data-test-id="orders-filters-lookup"
              customerSearchByName={customerSearchByNameEnabled}
            />
          ) : (
            <CustomersFilter onLight customersOnly />
          )}
        </Box>
        <Box height={57} width={400} mt={1} ml={2} style={{float: 'right'}}>
          <LookupFilter
            hasError={isLookupError}
            queryParams={queryParams}
            customerId={customerId}
            analyticsId={lookupAnalyticsId}
            userId={userId}
          />
        </Box>

        <Box display="inline-flex" mt={1} style={{flexFlow: 'wrap'}}>
          <Box mb={1}>
            <DateFilter />
          </Box>
          <Box mx={1} mb={1} />
          <Features name="DestinationSearchByName" resolution="disabled">
            <Features name="ProjectSitesFilter">
              <ProjectsSitesFilter onLight />
            </Features>
          </Features>
          <Features name="DestinationSearchByName">
            <SitesFilter customerId={customerId} limit={100} setSite={setSite} site={site} />
          </Features>
          <Features name="DestinationSearchByName" resolution="disabled">
            <Features name="ProjectSitesFilter" resolution="disabled">
              <SitesFilterOld onLight />
            </Features>
          </Features>
          <Features name="OrderStatusFilter">
            <Box mx={1} />
            <OrderStatusFilter />
          </Features>
          <Features name="RMCTestReports">
            <Box mx={1} /> <TestStatusFilter />
          </Features>
          <Features name="ShowSupplierFilter">
            <Box mx={1} />
            <SupplierNameFilterOrders />
          </Features>
          <Box mx={1} />
        </Box>
      </Box>
    </GenericFilterBar>
  )
}

export default Filters
