import {Typography} from '@material-ui/core'
import {Order} from '../Order.types'
import moment from 'moment'

interface PlacedDateProps {
  order: Order
}

export const PlacedDate: React.FC<PlacedDateProps> = ({order}) => {
  const timeZone = moment.tz.guess()
  const orderPlacedDate = moment.tz(order.placedDate, timeZone).format('DD/MM/YYYY')
  const orderPlacedTime = moment.tz(order.placedDate, timeZone).format('hh:mm')

  return (
    <>
      <Typography color="textPrimary" variant="subtitle1">
        {orderPlacedDate}
      </Typography>
      <Typography color="textPrimary" variant="body2">
        {orderPlacedTime}
      </Typography>
    </>
  )
}
