import {connect} from 'react-redux'

import {addJob, sheetRequest} from '../../AsyncJobQueue/AsyncJobQueue.action'
import {withRouteRequiringPermission} from '../../Molecules/PermissionBoundary'
import {errorsClear, selectErrorByKey} from '../../Organisms/Errors'
import {toggleResetOrderIntake} from '../../Organisms/OrderIntake/OrderIntake.action'
import {loadMore, selectOrders, setSortKey} from '../../Organisms/Orders'
import {setGlobalResultsPerPage} from '../../Organisms/ResultsPerPage'
import {fetchSites} from '../../Organisms/Sites'
import {PermissionTypes} from '../../Permissions'
import {AppState} from '../../Root.store'

import OrderPage from './Order'
import {fetchOrders} from './Order.action'
import {
  selectDateFilter,
  setDefaultDateFilter,
  setDateFilter,
  setLookupFilter
} from './Order.filters'
import {selectViewCurrent, selectViewCurrentOrders} from './Order.selector'

const mapStateToProps = (state: AppState) => ({
  items: selectViewCurrentOrders(state),
  view: selectViewCurrent(state),
  filters: selectOrders(state).filters,
  defaultFilters: selectOrders(state).defaultFilters,
  dateFilter: selectDateFilter(state),
  sortOrder: selectOrders(state).sortOrder,
  isFetching: selectOrders(state).isFetching,
  skip: selectOrders(state).skip,
  limit: selectOrders(state).limit,
  showLoadMore: selectOrders(state).showLoadMore,
  error: selectErrorByKey(state, 'order')
})
const mapDispatchToProps = (dispatch) => ({
  addJob: (data: any) => dispatch(addJob(data)),
  fetchOrders: (
    isGroupedDeliveries: boolean,
    isFilteringOutCancelledDeliveries?: boolean,
    isFilteringCancelledOrders?: boolean,
    isPumpServiceIncluded?: boolean,
    isServiceOrderIncluded?: boolean,
    includeDeliveryInstructions?: boolean,
    expandToOrderDocuments?: boolean,
    siteId?: string,
    analyticsId?: string
  ) =>
    dispatch(
      fetchOrders(
        isGroupedDeliveries,
        isFilteringOutCancelledDeliveries,
        isFilteringCancelledOrders,
        isPumpServiceIncluded,
        isServiceOrderIncluded,
        includeDeliveryInstructions,
        expandToOrderDocuments,
        siteId,
        analyticsId
      )
    ),
  setDefaultDateFilter: (props) => dispatch(setDefaultDateFilter(props)),
  setDateFilter: (props) => dispatch(setDateFilter(props)),
  setSortKey: (sortKey: string) => dispatch(setSortKey(sortKey)),
  fetchSites: (customerId: string) => dispatch(fetchSites(customerId)),
  loadMore: () => dispatch(loadMore()),
  sheetRequest: (args) => dispatch(sheetRequest(args)),
  errorsClear: () => dispatch(errorsClear()),
  toggleResetOrderIntake: () => dispatch(toggleResetOrderIntake()),
  setLookupFilter: ({entity, value, label}) => dispatch(setLookupFilter({entity, value, label})),
  setGlobalResultsPerPage: (amount: number) => dispatch(setGlobalResultsPerPage(amount))
})

const OrderWithData = connect(mapStateToProps, mapDispatchToProps)(OrderPage)

export const ROUTE = '/orders'

export default withRouteRequiringPermission({
  path: ROUTE,
  label: 'Orders & Deliveries',
  labelKey: 'order.navigationLabel',
  order: 10,
  routeProps: {exact: true},
  permissions: [PermissionTypes.VIEW_ORDERS_DELIVERIES, PermissionTypes.VIEW_ALL_DATA]
})(OrderWithData)
