import {Typography} from '@hconnect/uikit'
import {BreakPoint} from '@hconnect/uikit/src/lib/Hooks/useBreakPoints'
import {Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import DownloadPDFPNG from '../../../Assets/pdf-download.png'
import SelectItemPNG from '../../../Assets/select-item.png'
import {TrackingPageNames} from '../../../common/constants'
import {C60Lead} from '../../../Molecules/LeadBanners'
import {Features} from '../../../Organisms/Features'

interface Type {
  selectionEnabled: boolean
  fullScreen: boolean
  countryCode: string
  customerId: string
  isMediumScreen: boolean
  screenSize: BreakPoint
  analytics: {
    userId: string | undefined
    userMail: string | null | undefined
    userCountry: string | null | undefined
    userRoles: any[] | undefined
    userIsInternal: boolean | undefined
    customerId: string | undefined
    customerName: string | undefined
    filteredDateRange: {
      startDate: any
      endDate: any
    }
  }
}
// <Box display="flex" justifyContent="center" alignItems="center" height="100%">

export const OrderNotSelected: React.FC<Type> = ({
  selectionEnabled,
  countryCode,
  fullScreen,
  customerId,
  analytics,
  isMediumScreen,
  screenSize
}) => {
  const {t} = useTranslation()
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
      alignItems="center"
      height="100%"
    >
      <Box
        sx={{
          display: 'grid',
          height: '100%',
          placeItems: 'center'
        }}
      >
        <Box
          sx={{
            display: 'grid',
            placeItems: 'center',
            justifyItems: 'center'
          }}
        >
          <Box height={210}>
            <img
              src={selectionEnabled ? DownloadPDFPNG : SelectItemPNG}
              alt=""
              height="auto"
              width="auto"
            />
          </Box>
          <Box width="60%" mt={3}>
            <Typography variant="body1" align="center">
              {t(`order.orderDetails.${selectionEnabled ? 'pdfDownload' : 'selectOrder'}`)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Features name="C60LeadWidget">
        <Box mb={4.5}>
          <C60Lead
            countryCode={countryCode}
            fullScreen={fullScreen}
            customerId={customerId}
            analytics={analytics}
            isMediumScreen={isMediumScreen}
            screenSize={screenSize}
            entryPoint={TrackingPageNames.ORDERS}
          />
        </Box>
      </Features>
    </Box>
  )
}
