/* eslint-disable complexity */
import {Typography} from '@hconnect/uikit'
import {Grid} from '@material-ui/core'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import CloseIcon from '@mui/icons-material/Close'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import {Divider, IconButton, Skeleton} from '@mui/material'
import Box from '@mui/material/Box'
import classNames from 'classnames'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {DeliveryProgressBar} from '../../../Molecules/DeliveryProgressBar'
import StatusIcon from '../../../Molecules/StatusIcons'
import StatusTooltip from '../../../Molecules/StatusTooltip'
import {Features, useFeaturesState} from '../../../Organisms/Features'
import {statusFromOrderStatus} from '../../../Organisms/Orders'
import {PermissionTypes, usePermissions} from '../../../Permissions'
import {Delivery} from '../../Finance/Invoices/Delivery.types'
import {DeliveriesList, FieldValue, LoadingNumberValue, QRCodeValue} from '../components'
import {OrderCancellationButton} from '../components/OrderCancellationButton'
import {useDeliveriesByOrderId} from '../hooks'
import {Order} from '../Order.types'
import {getIsAGG, getIsCEM, getIsRMC, showProgressBar} from '../Order.utils'
import {usePlants} from '../OrderDetails/hooks'
import {OrderSummary} from '../OrderDetails/OrderDetailsHeader'

import {useOrderDetailsSMStyles} from './OrderDetailsSMStyle'

type OrderDetailsSMProps = {
  deliveries?: Delivery[]
  orderHistoryDetails: JSX.Element
  order: Order
  expand: () => void
  close: () => void
  isMobile: boolean
  customerId: string
  analytics?: any
}
export const OrderDetailsSM: React.FC<OrderDetailsSMProps> = ({
  // deliveries,
  orderHistoryDetails,
  expand,
  close,
  isMobile,
  order,
  customerId,
  analytics = {}
}) => {
  const {t} = useTranslation()
  const classes = useOrderDetailsSMStyles()
  const {getFeature, getResolutionByNameAndConstraint, normalized} = useFeaturesState()
  const status = order && statusFromOrderStatus(order.orderStatus, order.shippingType)
  const countryDisabledProgressBar = !getFeature('OrderProgressBar')
  const {hasPermission} = usePermissions()
  const hasInvoicePermission = hasPermission(PermissionTypes.VIEW_INVOICES)
  const hasChangeOrderPermission = hasPermission(PermissionTypes.CHANGE_ORDERS)
  const isGroupedDeliveries = getFeature('OrderIntakeGroupedDeliveries')

  const isDeliveryLoadFinished = getFeature('DeliveryLoadFinished')
  const epodEnabledForOrderExpand = getFeature('ePOD')
  const isCustomerDeliveryNumber = getFeature('CustomerDeliveryNumber')
  const isSloCountry = getFeature('SLO2Countries')
  const isFilteringOutCancelledDeliveries = getFeature('ExcludeAllCancelledDeliveries')
  const aggColumnsForRmc = getFeature('AggColumnsForRmc')
  const useTruckLicensePlate = getFeature('TruckLicensePlate')
  const isPumpServiceIncluded = getFeature('IncludePumpService')
  const isCEM = getIsCEM(order)
  const isRMC = getIsRMC(order)
  const isAGG = getIsAGG(order)
  const {
    data: deliveries,
    error: deliveriesError,
    isFetching: deliveriesFetching
  } = useDeliveriesByOrderId(
    customerId,
    order?.orderId,
    isGroupedDeliveries,
    isFilteringOutCancelledDeliveries,
    order?.orderIds,
    isPumpServiceIncluded
  )

  const {data: plant, isFetching: isPlantsFetching} = usePlants(
    order.businessLine,
    deliveries ? deliveries[0]?.plantName : null,
    deliveries ? deliveries[0]?.plantId : null
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isInvoiceDetailsDisabled = (invoiceId: string, country?: string) =>
    getResolutionByNameAndConstraint('InvoiceDetails', normalized, 'connectorId').disabled.includes(
      invoiceId.split('.')[0]
    ) || !getFeature('Finance')

  const [orderHistoryDetailsOpen, setOrderHistoryDetailsOpen] = useState(false)
  const [viewAll, setViewAll] = React.useState<boolean>(false)

  const hideCancelButtonStatuses = ['delivered', 'closed', 'paymentPending', 'rejected']
  const hideCancelButton = hideCancelButtonStatuses.includes(order.orderStatus)

  React.useEffect(() => {
    setViewAll(false)
  }, [order])

  const analyticsForDeliveries = {
    ...analytics,
    downloadedItemBusinessLine: order.businessLine,
    salesOrg: order.orgUnitId
  }

  const qrCode = <QRCodeValue order={order} deliveries={deliveries} />

  return (
    <Box p={2} height="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">{t('order.orderDetails.summary')}</Typography>
        <Box>
          {!isMobile && (
            <IconButton
              className={classes.icon}
              size="large"
              onClick={() => expand()}
              data-test-id="order-full-details"
            >
              <OpenInFullIcon />
            </IconButton>
          )}
          <IconButton
            className={classes.icon}
            size="large"
            onClick={() => close()}
            data-test-id="order-details-close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <Grid container>
        <Grid item xs={6}>
          <StatusTooltip
            title={t(`order.orderStatusLabel.tooltip.${status}`)}
            placement="top-start"
            data-test-id={`order-status-tooltip-${order.orderId}`}
          >
            <Box display="flex" alignItems="center" height="100%">
              <StatusIcon status={order.orderStatus} shippingType={order.shippingType} />
              <Box mx={0.5} />
              <Typography variant="h4">{t(`order.orderStatusLabel.${status}`)}</Typography>
            </Box>
          </StatusTooltip>
        </Grid>
        <Grid item xs={6} style={{marginTop: 8}}>
          <FieldValue
            dataTestId="shipping-type"
            label={t('order.orderDetails.shippingTypeLabel')}
            value={order.shippingType ? t(`order.orderDetails.${order.shippingType}`) : undefined}
            gutter
          />
        </Grid>
      </Grid>
      <OrderSummary
        order={order}
        plant={plant || undefined}
        isPlantsFetching={isPlantsFetching || deliveriesFetching}
        data-test-id="order-summary"
      />
      <LoadingNumberValue order={order} deliveries={deliveries} />
      {qrCode ? (
        <>
          <Divider sx={{mb: 3}} />
          {qrCode}
        </>
      ) : null}
      <Box data-test-id="delivery-progress-bar">
        {showProgressBar(
          order,
          countryDisabledProgressBar,
          getFeature('ShowStatusLoadedToInTransit')
        ) ? (
          <DeliveryProgressBar order={order} />
        ) : null}
      </Box>
      {deliveries?.length ? <Divider variant="middle" /> : null}
      <Box my={1} />
      {deliveriesFetching ? <Skeleton height="300px" width="100%" variant="rectangular" /> : null}
      {deliveries?.length ? (
        <DeliveriesList
          order={order}
          analytics={analyticsForDeliveries}
          deliveries={deliveries}
          error={deliveriesError}
          loading={deliveriesFetching}
          toggles={{
            isCustomerDeliveryNumber,
            useTruckLicensePlate,
            isAGG,
            isCEM,
            isRMC,
            epodEnabledForOrderExpand,
            isSloCountry,
            aggColumnsForRmc,
            isDeliveryLoadFinished,
            isFilteringOutCancelledDeliveries,
            hasInvoicePermission,
            isInvoiceDetailsDisabled
          }}
          customerId={customerId}
          viewAll={viewAll}
          setViewAll={setViewAll}
        />
      ) : null}
      <Features name="OrderHistory">
        <>
          <Box mt={1} className={classes.section} data-test-id="order-history-details">
            <Typography>{t('order.orderDetails.orderHistoryDetails')}</Typography>
            <IconButton
              className={classNames(classes.icon)}
              onClick={() => setOrderHistoryDetailsOpen(!orderHistoryDetailsOpen)}
            >
              {orderHistoryDetailsOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          </Box>
          <Divider className={classes.divider} />
          <Box>{orderHistoryDetailsOpen && orderHistoryDetails}</Box>
        </>
      </Features>
      <Features name="OrderIntakeOrderCancellation">
        {!hideCancelButton && hasChangeOrderPermission && (
          <Box pb={2} mt={4}>
            <OrderCancellationButton order={order} />
          </Box>
        )}
      </Features>
    </Box>
  )
}
