/* eslint-disable complexity */
import {useNotification, Typography} from '@hconnect/uikit'
import {Box, Button, CircularProgress} from '@material-ui/core'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {User} from '../../../../../apiclient'
import {trackEvent} from '../../../common/analytics'
import {useCancelOrder} from '../../../common/react-query/hooks/mutations/useCancelOrder'
import {useGetOrderCancellationOptions} from '../../../common/react-query/hooks/queries/useGetOrderCancellationOptions'
import {useOrderIntakeStyles} from '../../../Hooks/OrderIntake/useOrderIntakeStyles'
import {OrderCancellationCutOffTime} from '../../../OrderIntake/components/OrderCancellationCutOffTime'
import {OrderCancellation} from '../../../OrderIntake/declarations/types'
import {AppState} from '../../../Root.store'
import {getUserProfile} from '../../../UserProfile/UserProfile.selectors'
import {Order, PurchaseOrderType, ShippingType} from '../Order.types'
import {BusinessLineType, BusinessLineTypes} from '../../../common/types'
import moment from 'moment'
import {useCheckOrdersWithDeliveries} from '../hooks/useCheckOrdersWithDeliveries'

interface OrderCancellationButtonProps {
  order: Order
}

export const OrderCancellationButton: React.FC<OrderCancellationButtonProps> = ({order}) => {
  const {t} = useTranslation()
  const {notify} = useNotification()
  const classes = useOrderIntakeStyles()
  const {mutate} = useCancelOrder()
  const userProfile = useSelector<AppState, User | null>(
    (state) => getUserProfile(state).userProfile
  )
  const shippingTypeName = Object.keys(ShippingType).find(
    (key) => ShippingType[key] === order.shippingType
  )
  const {
    data: cancellationOptions,
    isLoading: isLoadingCancellationOptions,
    isError: isErrorCancellationOptions
  } = useGetOrderCancellationOptions(userProfile?.country, order.orgUnitId, shippingTypeName)
  const [askForConfirmation, setAskForConfirmation] = useState(false)
  const [cancellationInfoText, setCancellationInfoText] = useState('')
  const [isLoadingCancellation, setIsLoadingCancellation] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [hideCancellationButton, setHideCancellationButton] = useState(false)

  const storedOrdersPendingCancellation: string[] =
    localStorage.getItem('ordersPendingCancellation')?.split(',') ?? []
  const [ordersPendingCancellation, setOrdersPendingCancellation] = useState<string[]>(
    storedOrdersPendingCancellation
  )

  const orderDeliveriesData = useCheckOrdersWithDeliveries(order)

  const allowedToCancelStatuses = ['pending', 'confirmed', 'reserved']
  const allowedBusinessLines: BusinessLineTypes[] = [BusinessLineType.CEM, BusinessLineType.AGG]
  const isOrderPlacedViaHub = order?.purchaseOrderType === PurchaseOrderType.Hub
  const isOrderStatusAllowsCancellation = allowedToCancelStatuses.includes(order?.orderStatus)
  const isBusinessLineAllowed = order?.businessLine
    ? allowedBusinessLines.includes(order?.businessLine)
    : false
  const isOrderStatusCancelled = (order?.orderStatus as string) === 'cancelled'
  const isOrderPendingCancellation = ordersPendingCancellation.includes(order.orderId)
  const isOrderHasDeliveries = orderDeliveriesData.hasDeliveries
  const hideCutOffTime = isErrorCancellationOptions || hideCancellationButton

  const shouldButtonBeDisabled =
    !isOrderPlacedViaHub ||
    !isOrderStatusAllowsCancellation ||
    isOrderStatusCancelled ||
    isLoadingCancellationOptions ||
    isErrorCancellationOptions ||
    !isBusinessLineAllowed

  useEffect(() => {
    /*
    We check first if the button should be disabled.
    */
    setIsLoadingCancellation(orderDeliveriesData.isDeliveriesFetching)
    setIsButtonDisabled(shouldButtonBeDisabled || isOrderHasDeliveries)
    setAskForConfirmation(false)
    setHideCancellationButton(false)

    if (isErrorCancellationOptions && !isLoadingCancellationOptions) {
      setCancellationInfoText(t('orderIntake.cancellationNotPossibleError'))
      return
    }

    /*
    If the order is cancelled and not pending cancellation, we remove it from the list of orders pending cancellation in local storage
    */
    if (isOrderStatusCancelled && isOrderPendingCancellation) {
      const filteredOrdersPendingCancellation = storedOrdersPendingCancellation.filter(
        (orderId) => orderId !== order.orderId
      )
      localStorage.setItem('ordersPendingCancellation', filteredOrdersPendingCancellation.join(','))
    }

    if (isOrderPendingCancellation) {
      setHideCancellationButton(true)
      setIsButtonDisabled(true)
      setCancellationInfoText(t('orderIntake.cancellationProcessing'))
    }
  }, [
    shouldButtonBeDisabled,
    order,
    isErrorCancellationOptions,
    ordersPendingCancellation,
    setIsButtonDisabled,
    isLoadingCancellationOptions,
    isOrderPendingCancellation,
    isOrderStatusCancelled,
    orderDeliveriesData
  ])

  const cancelOrder = () => {
    const cancelOrderPayload: OrderCancellation = {
      cancelReasonNotes: 'cancellation request',
      orderItemNumber: order.orderNumber,
      orderItemPositionNumber: order.lineItems[0].itemNumber,
      prefix: order.orderId.split('.')[0],
      businessLineCode: order.businessLine,
      checkSum: order.checkSum,
      timeZone: moment.tz.guess()
    }

    setIsLoadingCancellation(true)
    setAskForConfirmation(false)
    trackEvent('hubOrderIntakeCancellationRequestSubmitted', {
      businessLine: order.businessLine,
      cancelledByOrderPlacer: order.orderPlacerName === userProfile?.name,
      country: order.customer.countryId,
      customerId: order.customer.customerId,
      orderId: order.orderId
    })

    mutate(
      {cancelOrderPayload},
      {
        onSuccess: () => {
          setIsLoadingCancellation(false)
          notify('success', t('orderIntake.cancellationSucceeded', {persist: true}))
          setCancellationInfoText(t('orderIntake.cancellationProcessing'))

          setHideCancellationButton(true)
          setOrdersPendingCancellation([...ordersPendingCancellation, order.orderId])
          localStorage.setItem(
            'ordersPendingCancellation',
            [...ordersPendingCancellation, order.orderId].join(',')
          )
        },
        onError: () => {
          setIsLoadingCancellation(false)
          notify('error', t('orderIntake.cancellationFailed', {persist: true}))
        }
      }
    )
  }

  const handleAbortCancellation = () => {
    setAskForConfirmation(false)
  }

  const handleCancellation = () => {
    if (!askForConfirmation) {
      setAskForConfirmation(true)
      return
    } else {
      cancelOrder()
    }
  }

  const getCancellationInfoText = () => {
    switch (true) {
      case isOrderHasDeliveries:
        return t('orderIntake.cancellationNotPossible')
      case isOrderStatusCancelled:
        return t('orderIntake.orderCancelledInfo')
      case !isOrderPlacedViaHub:
        return t('orderIntake.cancellationNotPossibleType')
      case !isOrderStatusAllowsCancellation:
        return t('orderIntake.cancellationNotPossibleStatus')
      case isErrorCancellationOptions:
        return t('orderIntake.cancellationNotPossibleError')
      case isOrderPendingCancellation:
        return t('orderIntake.cancellationProcessing')
      case !isBusinessLineAllowed:
        return t('orderIntake.cancellationNotPossibleBusinessLine')
      default:
        return cancellationInfoText
    }
  }

  const getConfirmationButtonContent = () => {
    if (isLoadingCancellation) {
      return <CircularProgress size={20} color="inherit" />
    } else if (isOrderStatusCancelled) {
      return t('orderIntake.orderCancelled')
    } else {
      return !askForConfirmation
        ? t('orderIntake.cancelOrder')
        : t('orderIntake.confirmCancellation')
    }
  }

  const handelTimeToCancelOver = (isTimeToCancelOver: boolean) => {
    /*
     If shouldButtonBeDisabled is true, we don't need to check for cut off time
    */
    if (shouldButtonBeDisabled || isOrderPendingCancellation || isOrderHasDeliveries) return
    setIsButtonDisabled(isTimeToCancelOver)
    isTimeToCancelOver && setCancellationInfoText(t('orderIntake.cancellationNotPossibleStatus'))
  }

  return (
    <>
      <Box
        width={1}
        mt={2}
        mb={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        data-test-id="order-cancellation-buttons"
      >
        {!hideCancellationButton && !isOrderStatusCancelled ? (
          <Box width={1} display="flex" flexDirection="column" alignItems="center">
            <Box width={0.9}>
              <Button
                className={classes.cancelOrderButton}
                onClick={handleCancellation}
                startIcon={isLoadingCancellation || isOrderStatusCancelled ? null : <CloseIcon />}
                disabled={isButtonDisabled || isLoadingCancellation}
                data-test-id="cancel-order-button"
              >
                {getConfirmationButtonContent()}
              </Button>
            </Box>
            <Box mt={2} />
            {askForConfirmation && (
              <Box width={0.9}>
                <Button
                  className={classes.orderButton}
                  onClick={handleAbortCancellation}
                  startIcon={<ArrowBackIcon />}
                  data-test-id="abort-cancellation-button"
                >
                  {t('orderIntake.abortCancellation')}
                </Button>
              </Box>
            )}
          </Box>
        ) : null}
        {isButtonDisabled && !isOrderStatusCancelled ? (
          <Box textAlign={'center'}>
            <Typography
              variant="body2"
              customColor="textPrimarySoft"
              style={{fontWeight: 700, fontSize: 14}}
              data-test-id="cancellation-info-text"
            >
              {getCancellationInfoText()}
            </Typography>
          </Box>
        ) : null}
        <Box mt={1} />
        {!hideCutOffTime && (
          <OrderCancellationCutOffTime
            order={order}
            onCancelCutOffTimeReached={handelTimeToCancelOver}
            cancellationOptions={cancellationOptions}
            isLoadingCancellationOptions={isLoadingCancellationOptions}
            isCancellationButtonDisabled={shouldButtonBeDisabled || isButtonDisabled}
          />
        )}
      </Box>
    </>
  )
}
