import {makeStyles, Theme} from '@material-ui/core'

export const useOrderDetailsSMStyles = makeStyles((theme: Theme) => ({
  card: {
    width: '100%',
    backgroundColor: '#F7F7F7',
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25)
  },
  linearOverlay: {
    maskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)'
  },
  divider: {
    marginBottom: '8px !important'
  },
  icon: {
    border: 'none',
    boxShadow: 'none',
    color: `${theme.palette.secondary.dark} !important`,
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      background: 'transparent'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem'
    }
  },
  headLine: {
    color: theme.palette.secondary.main
  },
  link: {
    color: '#016AD4' // theme.palette.text.link does not exist on type Theme
  },
  section: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  status: {
    textTransform: 'capitalize'
  }
}))
